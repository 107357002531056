import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    preloader: 0
  },
  getters: {
    preloader(state) {
      return state.preloader
    }
  },
  mutations: {
    setPreloader(state, data) {
      console.log(data)
      state.preloader = data
    }
  },
  actions: {
  },
  modules: {
  }
})
