import { render, staticRenderFns } from "./EventTab.vue?vue&type=template&id=20067f32&scoped=true&"
import script from "./EventTab.vue?vue&type=script&lang=js&"
export * from "./EventTab.vue?vue&type=script&lang=js&"
import style0 from "./EventTab.vue?vue&type=style&index=0&id=20067f32&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20067f32",
  null
  
)

export default component.exports