<template>
    <div v-if="preloader" class="preloader-wrap">
        <div class="preloader">
            <svg class="preloader-logo" width="240" height="240" viewBox="0 0 554 370" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1113_553)">
            <path d="M237.138 24.6809C225.557 13.0879 210.801 5.19308 194.738 1.99486C178.675 -1.20337 162.025 0.438627 146.893 6.71319C131.762 12.9878 118.829 23.6131 109.73 37.2456C100.631 50.8781 95.7749 66.9055 95.7749 83.3011C95.7749 99.6967 100.631 115.724 109.73 129.357C118.829 142.989 131.762 153.614 146.893 159.889C162.025 166.164 178.675 167.806 194.738 164.607C210.801 161.409 225.557 153.514 237.138 141.921L178.588 83.2942L237.138 24.6809Z" fill="#FFC700"/>
            <path d="M492.633 4.24015H428.671V162.225H487.85C489.435 162.335 491.034 162.417 492.647 162.417L492.633 4.24015Z" fill="#E83E24"/>
            <path d="M502.692 37.5419V161.24C517.022 158.437 529.932 150.735 539.214 139.452C548.495 128.169 553.57 114.007 553.57 99.391C553.57 84.7748 548.495 70.6131 539.214 59.3302C529.932 48.0474 517.022 40.3451 502.692 37.5419Z" fill="#E83E24"/>
            <path d="M298.572 4.37698H264.431V41.0582H298.572V4.37698Z" fill="#E83E24"/>
            <path d="M354.703 53.2351V4.37698H308.126V162.321H354.731V53.2351H354.703Z" fill="#E83E24"/>
            <path d="M398.411 4.37698H364.257V41.0582H398.411V4.37698Z" fill="#E83E24"/>
            <path d="M51.3047 209.537C36.8827 212.255 23.8624 219.931 14.4943 231.24C5.12618 242.548 -0.000976562 256.777 -0.000976562 271.468C-0.000976562 286.159 5.12618 300.389 14.4943 311.697C23.8624 323.005 36.8827 330.682 51.3047 333.4V209.537Z" fill="#E83E24"/>
            <path d="M116.894 209.537V333.4C131.316 330.682 144.336 323.005 153.704 311.697C163.072 300.389 168.199 286.159 168.199 271.468C168.199 256.777 163.072 242.548 153.704 231.24C144.336 219.931 131.316 212.255 116.894 209.537Z" fill="#E83E24"/>
            <path d="M63.7556 76.5901V92.4748H54.4892V76.5901H63.7556ZM69.2225 71.1173H49.036V97.8928H69.2088V71.1994L69.2225 71.1173Z" fill="#E83E24"/>
            <path d="M63.7556 9.83605V23.8874H37.3095V142.811H63.7556V156.876H36.9268V156.807H20.6628V9.83605H63.7556ZM69.2225 4.36328H15.2096V162.253H31.4736V162.321H69.2088V137.365H42.7491V29.2781H69.2088V4.37698L69.2225 4.36328Z" fill="#E83E24"/>
            <path d="M402.265 228.883C390.684 217.29 375.929 209.396 359.865 206.197C343.802 202.999 327.152 204.641 312.021 210.916C296.89 217.19 283.957 227.816 274.858 241.448C265.759 255.081 260.902 271.108 260.902 287.504C260.902 303.899 265.759 319.927 274.858 333.559C283.957 347.192 296.89 357.817 312.021 364.091C327.152 370.366 343.802 372.008 359.865 368.81C375.929 365.612 390.684 357.717 402.265 346.124L343.66 287.51L402.265 228.883Z" fill="#FFC700"/>
            <path d="M107.34 257.397V208.538H60.7488V366.482H107.368V257.397H107.34Z" fill="#E83E24"/>
            <path d="M235.689 280.752V296.623H226.409V280.752H235.689ZM241.156 275.279H220.997V302.068H241.156V275.279Z" fill="#E83E24"/>
            <path d="M235.689 213.984V228.049H209.257V346.972H235.689V361.023H208.874V360.955H192.61V213.984H235.689ZM241.156 208.511H187.171V366.414H203.435V366.482H241.156V341.513H214.71V233.494H241.156V208.511Z" fill="#E83E24"/>
            <path d="M454.16 208.538H420.019V245.22H454.16V208.538Z" fill="#E83E24"/>
            <path d="M510.291 257.397V208.538H463.7V366.482H510.319V257.397H510.291Z" fill="#E83E24"/>
            <path d="M553.999 208.538H519.845V245.22H553.999V208.538Z" fill="#E83E24"/>
            </g>
            <defs>
            <clipPath id="clip0_1113_553">
            <rect width="554" height="370" fill="white"/>
            </clipPath>
            </defs>
            </svg>
            <v-progress-circular indeterminate :size="300" color="#E83E24"></v-progress-circular>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
        }
    },  
    computed: {
        ...mapGetters(['preloader'])
    },
}
</script>

<style lang="sass" scoped>
@import '../variables.sass'
.preloader
    position: relative
    &-logo 
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

    &-wrap 
        width: 100vw
        height: 100vh
        background: white
        position: fixed
        top: 0
        left: 0
        z-index: 1000
        overflow: hidden
        display: flex
        justify-content: center
        align-items: center 

</style>