<template>
    <div class="color" v-if="deadline>=now || launchDate > 0">
        <div class="timer container">
            <h4 class="title">ДО ФЕСТИВАЛЯ ОСТАЛОСЬ:</h4>
            <div class="timerBox">
                <h4 class="days">{{ leftFillNum(getDays, 2) }}:</h4>
                <h4 class="hours">{{ leftFillNum(getHours % 24, 2)}}:</h4>
                <h4 class="minutes">{{ leftFillNum(getMinutes % 60, 2) }}:</h4>
                <h4 class="seconds">{{ leftFillNum(getSeconds % 60, 2) }}</h4>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapMutations } from "vuex";

export default {
    data() {
        return {
            deadline: '',
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            launchDate: 0,
            now: '',
            timeId: null
        }
    },
    computed: {
        ...mapGetters(['preloader']),
        getSeconds() {
            return parseInt(this.launchDate/1000)
        },
        getMinutes() {
            return parseInt(this.getSeconds/60)
        },
        getHours() {
            return parseInt(this.getMinutes/60)
        },
        getDays() {
            return parseInt(this.getHours/24)
        },

    },
    created() {
        this.setPreloader(++this.preloader)

        axios
        .get('/api/festival')
        .then( (response) => {
            // const data = {"data":{"date":"2023-09-02-10-00-00","now_date":"2023-09-01-09-59-50","coordinates":"56.458120, 84.960148"}}
            this.deadline = new Date(...response.data.data.date.split('-'))
            this.now = new Date(...response.data.data.now_date.split('-'))      
            this.launchDate = this.deadline.getTime() - this.now.getTime()
            
            this.timeId = setInterval(() => {
                this.setInactivityTime()
            }, 1000);
        })

        .finally(() => {
            this.setPreloader(--this.preloader)
        })

    },
    methods: {
        ...mapMutations(['setPreloader']),

        leftFillNum(num, targetLength) {
            return num.toString().padStart(targetLength, "0");
        },
        setInactivityTime() {
            this.launchDate -= 1000
            if (this.launchDate < 0) {
                this.clearTimer();
            } 
        },
        clearTimer() {
            clearInterval(this.timeId);
            this.launchDate = 0
        }

    },
    beforeDestroy() {
        clearInterval(this.timeId);
        this.launchDate = 0
    }
}
</script>

<style lang="sass" scoped>
@import '../variables.sass'
.color
    width: 100%
    background: $main-green
.container
    @media (max-width: 1460px)
        width: 90%
.timer
    height: 100px
    box-sizing: border-box
    display: flex
    justify-content: center
    align-items: center
    white-space: nowrap
    @media (max-width: 1460px)
        padding: 16px 0
        flex-direction: column
    @media (max-width: 875px)
        height: 88px
    h4
        color: #fff
    .title
        margin-right: desktopSize(358)
        @media (max-width: 1460px)
            text-align: left
            margin-right: 0
        @media (max-width: 875px)
            margin-right: 0
            margin-bottom: 8px


    .timerBox
        display: flex
        flex-direction: row
        width: 220px
        @media (max-width: 870px)
            width: 83px
</style>